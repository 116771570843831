import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUnit } from 'effector-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/shared/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/shared/components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shared/components/ui/form';
import { Input } from '@/shared/components/ui/input';
import { MAX_LENGTH_MESSAGE, MIN_LENGTH_MESSAGE, REQUIRED_FIELD_MESSAGE } from '@/shared/constants/error-messages';

import AtIcon from '../icons/at-icon';
import CheckMarkIcon from '../icons/checkmark-icon';
import { TextGradient } from '../text-gradient';
import { RadioGroup, RadioGroupItem } from '../ui/radio-group';
import { $isContactFormSentSuccessfully, contactFormDataSent, onContactFormClose } from './model';
import { CallbackFormData } from './types';

interface CallbackFormProps {
  children: React.ReactNode;
}

const contactFormSchema = z.object({
  name: z
    .string()
    .min(2, { message: MIN_LENGTH_MESSAGE })
    .max(250, { message: MAX_LENGTH_MESSAGE })
    .trim()
    .toLowerCase()
    .refine((value) => !!value, {
      message: REQUIRED_FIELD_MESSAGE,
      path: [],
    }),
  telegram: z
    .string()
    .min(2, { message: MIN_LENGTH_MESSAGE })
    .max(250, { message: MAX_LENGTH_MESSAGE })
    .trim()
    .toLowerCase()
    .refine((value) => !!value, {
      message: REQUIRED_FIELD_MESSAGE,
      path: [],
    }),

  source: z.enum(['social', 'search', 'linkedin', 'other'], {
    required_error: 'Выберите один из вариантов',
  }),

  otherValue: z.string().optional(),
});

export const CallbackForm = (props: CallbackFormProps) => {
  const { children } = props;

  const { sendContactFormData, onContactFormClosed, isContactFormSentSuccessfully } = useUnit({
    onContactFormClosed: onContactFormClose,
    sendContactFormData: contactFormDataSent,
    isContactFormSentSuccessfully: $isContactFormSentSuccessfully,
  });

  const callbackForm = useForm<z.infer<typeof contactFormSchema>>({
    resolver: zodResolver(contactFormSchema),
    mode: 'onBlur',

    defaultValues: {
      name: '',
      telegram: '',
    },
  });

  const { formState, handleSubmit, setValue, reset } = callbackForm;

  const [isOtherValueFieldVisible, setOtherValueFieldVisible] = useState(false);

  const onRadioChange = (value: any) => {
    setValue('source', value);
    setOtherValueFieldVisible(value === 'other');
    setValue('otherValue', '');
  };

  const onSubmit = (values: z.infer<typeof contactFormSchema>) => {
    const { name, telegram, source, otherValue } = values;

    const callbackFormData: CallbackFormData = {
      name,
      telegram,
      source,
      otherValue,
    };

    sendContactFormData(callbackFormData);

    if (isContactFormSentSuccessfully) {
      reset();
    }
  };

  return (
    <Dialog
      modal
      onOpenChange={() => {
        setOtherValueFieldVisible(false);
        reset();
        onContactFormClosed();
      }}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>

      <DialogContent
        className="min-h-[400px] max-w-[90%] rounded-md sm:max-w-[425px]"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        {isContactFormSentSuccessfully && (
          <>
            <div className="flex h-full flex-col items-center justify-center pt-10">
              <CheckMarkIcon className="mb-4 h-10 w-10 shrink-0 rounded-full bg-brand-accent-light p-[2px] text-white dark:bg-brand-accent-normal" />

              <div className="text-center">
                <TextGradient text="Заявка отправлена!" className="mb-1 header-h1" />
                <p className="text-muted-foreground">Скоро мы вам напишем</p>
              </div>
            </div>
          </>
        )}

        {!isContactFormSentSuccessfully && (
          <>
            <DialogHeader>
              <DialogTitle>Оставить заявку</DialogTitle>
              <DialogDescription>Мы&nbsp;свяжемся с&nbsp;вами в&nbsp;мессенджере</DialogDescription>
            </DialogHeader>

            <Form {...callbackForm}>
              <form className="max-w-5xl space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <FormField
                  control={callbackForm.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>
                        Имя<sup className="text-red-600">*</sup>
                      </FormLabel>

                      <FormControl>
                        <Input {...field} placeholder="Ваше имя" />
                      </FormControl>

                      <FormMessage className="font-normal" />
                    </FormItem>
                  )}
                />

                <FormField
                  control={callbackForm.control}
                  name="telegram"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>
                        Telegram<sup className="text-red-600">*</sup>
                      </FormLabel>

                      <FormControl>
                        <Input
                          {...field}
                          placeholder="Ваш тег в телеграм"
                          startAdornment={<AtIcon className="h-5 w-5" />}
                        />
                      </FormControl>

                      <FormMessage className="font-normal" />
                    </FormItem>
                  )}
                />

                <FormField
                  control={callbackForm.control}
                  name="source"
                  render={({ field }) => (
                    <FormItem className="space-y-3">
                      <FormLabel>
                        Как вы о нас узнали<sup className="text-red-600">*</sup>
                      </FormLabel>

                      <FormControl>
                        <RadioGroup
                          onValueChange={(value) => {
                            field.onChange(value);
                            onRadioChange(value);
                          }}
                          defaultValue={field.value}
                          className="flex flex-col space-y-1"
                        >
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="social" />
                            </FormControl>
                            <FormLabel className="font-normal">Реклама в соц. сетях</FormLabel>
                          </FormItem>

                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="search" />
                            </FormControl>
                            <FormLabel className="font-normal">Через поиск в интернете</FormLabel>
                          </FormItem>

                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="linkedin" />
                            </FormControl>
                            <FormLabel className="font-normal">LinkedIn</FormLabel>
                          </FormItem>

                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="other" onChange={() => onRadioChange} />
                            </FormControl>
                            <FormLabel className="font-normal">Другое</FormLabel>
                          </FormItem>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {isOtherValueFieldVisible && (
                  <FormField
                    control={callbackForm.control}
                    name="otherValue"
                    render={({ field }) => (
                      <FormItem className="!mt-2 flex flex-col">
                        <FormControl>
                          <Input {...field} placeholder="Расскажите, откуда вы о нас узнали" />
                        </FormControl>

                        <FormMessage className="font-normal" />
                      </FormItem>
                    )}
                  />
                )}

                <div>
                  <hr className="m-0 mb-4 h-px w-full border-none bg-gradient-to-r from-neutral-200/0 via-neutral-500/30 to-neutral-200/0" />

                  <Button
                    disabled={!formState.isValid || formState.isSubmitting}
                    variant="cta"
                    type="submit"
                    className="mx-auto block tracking-wide"
                    onClick={() => handleSubmit(onSubmit)}
                  >
                    Отправить
                  </Button>
                </div>
              </form>
            </Form>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
