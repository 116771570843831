import Link from 'next/link';

import LinkedInIcon from '@/shared/components/icons/socials/linkedin-icon';
import TelegramIcon from '@/shared/components/icons/socials/telegram-icon';
import { Logo } from '@/shared/components/logo';
import { Button } from '@/shared/components/ui/button';
import { Separator } from '@/shared/components/ui/separator';

export const Footer = () => {
  return (
    <footer className="border-t border-border/40 bg-background/95 pb-10 pt-6">
      <div className="container">
        <div className="grid grid-cols-1 gap-0 md:grid-cols-[minmax(150px,220px),1fr]">
          <div>
            <Logo />

            <div className="mt-2 flex items-center">
              <Button
                variant="link"
                size="icon"
                className="hover:text-brand-accent-normal dark:hover:text-brand-accent-light"
              >
                <Link
                  aria-label="Перейти в Telegram школы"
                  href="https://t.me/Anglichannka"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TelegramIcon className="h-8 w-8" />
                </Link>
              </Button>

              <Button
                variant="link"
                size="icon"
                className="hover:text-brand-accent-normal dark:hover:text-brand-accent-light"
              >
                <Link
                  aria-label="Перейти в LinkedIn школы"
                  href="https://www.linkedin.com/in/anglichannka/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInIcon className="h-8 w-8" />
                </Link>
              </Button>
            </div>

            <Separator className="my-4 w-full md:hidden" />

            <div className="mt-4 hidden select-none space-y-1 text-xs font-light tracking-tighter text-muted-foreground/50 md:block">
              <Separator className="mb-3 w-full" />

              <p>ИП Сладких Анна Святославовна</p>
              <p>+79512418196</p>
              <p>ИНН 745312661384</p>
              <p>ОГРНИП 323745600057252</p>
              <Button
                variant="link"
                className="p-0 text-xs font-light text-muted-foreground/50 hover:text-brand-accent-light"
                size="link"
              >
                <Link href="mailto:support@anglichannka.ru">support@anglichannka.ru</Link>
              </Button>
            </div>
          </div>

          <nav className="grid w-full max-w-2xl grid-cols-1 gap-3 xss:grid-cols-2 sm:grid-cols-3 md:ml-4 md:px-2 lg:ml-20 lg:gap-2">
            <div className="flex w-full flex-col items-start space-y-2">
              <h2 className="text-sm font-semibold lg:text-base">Услуги</h2>

              <Button variant="link" size="link" className="p-0">
                <Link className="text-xs text-muted-foreground lg:text-sm" href="/individual-lessons">
                  Индивидуальные занятия
                </Link>
              </Button>

              <Button variant="link" size="link" className="p-0">
                <Link className="text-xs text-muted-foreground lg:text-sm" href="/pair-lessons">
                  Парные занятия
                </Link>
              </Button>

              <Button variant="link" size="link" className="p-0">
                <Link className="text-xs text-muted-foreground lg:text-sm" href="/group-lessons">
                  Групповые занятия
                </Link>
              </Button>

              <Button variant="link" size="link" className="p-0">
                <Link className="text-xs text-muted-foreground lg:text-sm" href="/speaking-clubs">
                  Разговорный клуб
                </Link>
              </Button>
            </div>

            <div className="mx-auto flex w-full flex-col items-start space-y-2 xs:pl-6">
              <h2 className="text-sm font-semibold lg:text-base">Компания</h2>

              <Button variant="link" size="link" className="p-0">
                <Link className="text-xs text-muted-foreground lg:text-sm" href="/for-companies">
                  Для компаний
                </Link>
              </Button>

              <Button variant="link" size="link" className="p-0">
                <Link className="text-xs text-muted-foreground lg:text-sm" href="/team">
                  Команда
                </Link>
              </Button>

              <Button variant="link" size="link" className="p-0">
                <Link className="text-xs text-muted-foreground lg:text-sm" href="/cases">
                  Кейсы
                </Link>
              </Button>

              <Button variant="link" size="link" className="p-0">
                <Link className="text-xs text-muted-foreground lg:text-sm" href="/payment">
                  Оплата
                </Link>
              </Button>
            </div>

            <div className="mx-auto ml-0 flex w-full flex-col items-start space-y-2 xss:col-span-1 xs:col-span-2 sm:col-span-1">
              <h2 className="text-sm font-semibold lg:text-base">Документы</h2>

              <Button variant="link" size="link" className="p-0">
                <Link className="text-xs text-muted-foreground lg:text-sm" href="/public-offer">
                  Публиная оферта
                </Link>
              </Button>

              <Button variant="link" size="link" className="p-0">
                <Link className="text-xs text-muted-foreground lg:text-sm" href="/payment-rules">
                  Правила оплаты
                </Link>
              </Button>

              <Button variant="link" size="link" className="max-w-[140px] whitespace-normal p-0 text-left">
                <Link className="text-xs text-muted-foreground lg:text-sm" href="/agreement">
                  Соглашение об обработке персональных данных
                </Link>
              </Button>
            </div>
          </nav>

          <div className="mt-4 select-none space-y-1 text-xs font-light tracking-tighter text-muted-foreground/50 md:hidden">
            <Separator className="mb-3 w-full" />

            <p>ИП Сладких Анна Святославовна</p>
            <p>+79512418196</p>
            <p>ИНН 745312661384</p>
            <p>ОГРНИП 323745600057252</p>
            <Button
              variant="link"
              className="p-0 text-xs font-light text-muted-foreground/50 hover:text-brand-accent-light"
              size="link"
            >
              <Link href="mailto:support@anglichannka.ru">support@anglichannka.ru</Link>
            </Button>
          </div>
        </div>
      </div>
    </footer>
  );
};
